<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('exam_schedule')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('exam_schedule')" :is-filter="false"/>
            </template>

            <datatable
                :isLoading="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                v-show="datatable.showTable"
                :resultTitle="false"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
            >
            </datatable>
            <div class="mt-3" v-if="['OL','L'].includes(user.active_student_program.program_level)">
                <ExamSchedulesStudentWarning />
            </div>
        </app-layout>
    </div>
</template>
<script>
import ExamScheduleService from "@/services/ExamScheduleService";
import AppLayout from "@/layouts/AppLayout";
import ExamSchedulesStudentWarning from "@/components/page/ExamSchedulesStudentWarning.vue";

import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";
import Datatable from "@/components/datatable/Datatable";

export default {
    components: {
        HeaderMobile,
        Header,
        Datatable,
        AppLayout,
        ExamSchedulesStudentWarning
    },
    metaInfo() {
        return {
            title: this.$t("exam_schedule"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_code",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_name"),
                        field: this.getLocaleField("course_name"),
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("instructor_name"),
                        field: "instructor_name",
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("exam_type"),
                        field: this.getLocaleField("type"),
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("date"),
                        field: "date",
                        sortable: false,
                        hidden:false
                    },
                    {
                        label: this.toUpperCase("start_time"),
                        field: "start_time",
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("end_time"),
                        field: "end_time",
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("campus"),
                        field: this.getLocaleField('campus'),
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("building"),
                        field: this.getLocaleField('building'),
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("classroom"),
                        field: this.getLocaleField('classroom'),
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    sort: "-id",
                    page: 1,
                    limit: 20,
                },
            },
            userId:null,
        };
    },

    computed: {
        user() {
            return this.$store.getters['auth/getUser'];
        },
    },
    created() {
        this.$store.dispatch('auth/initUser')
            .then(()=>this.fetchCourseSchedule());
    },
    methods: {
        fetchCourseSchedule() {
            this.getRows();
        },
        async getFromUser() {
            let user = await this.$store.getters['auth/getUser'];
            if(user && user.student && user.student.student_number){
                return user.student.student_number
            }
        },
        async getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            try {
                this.userId= await this.getFromUser();
                return ExamScheduleService.getOfStudent(this.userId)
                                            .then((response) => {
                                                this.datatable.rows = response.data.data.data;

                                            }).catch(err=>this.showErrors(err))
                                            .finally(() => {
                                                this.datatable.isLoading = false;
                                            });
            }
            catch (err){
                this.showErrors(err)
            }

        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
    },
};
</script>
